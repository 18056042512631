@import 'Styles/includes';

/**
*
* CardArticleFull
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.CardArticleFull {
    $root: &;

    position: relative;
    width: 100%;
    display: flex;

    // Adds background color
    @include sensus-bg-modifiers();

    &--HasBackground {
        padding: 48px 0;

        @include media(md) {
            padding: 80px 0;
        }
    }

    &__Icon {
        font-size: 4.6rem;
        display: block;
        margin-bottom: 16px;
        color: $text;

        &--Red {
            color: $red-icon-color;
        }

        @include media(md) {
            font-size: 4.6rem;
            margin-bottom: 32px;
        }
    }

    &__Background {
        position: absolute;
        width: 100vw;
        height: 100%;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        background-color: inherit;
        overflow: hidden;
    }

    &__Container {
        display: flex;
        flex-direction: column-reverse;
        margin: 0 auto;

        @include media(md) {
            display: block;
        }

        &::before {
            content: '';
            position: absolute;
            display: none;
            background: white;
            width: 100%;
            height: 100%;
            background-color: white;
            border-radius: 4px;
            opacity: 0.93;
        }

        #{$root}--HasBackground & {
            position: relative;
            width: 100%;

            &::before {
                display: block;
            }
        }
    }

    &__Content {
        position: relative;
        margin: 0 auto;
        text-align: center;
        color: $text;

        @include media(md) {
            max-width: 610px;
        }

        #{$root}--HasBackground & {
            padding: 24px 15px;

            @include media(md) {
                padding: 80px 0;
            }
        }
    }

    &__Title {
        font-size: 2.4rem;
        font-weight: bold;
        margin-bottom: 16px;
        line-height: 1.2;

        @include media(md) {
            font-size: 3.6rem;
        }
    }

    &__Image {
        position: relative;
        width: 100%;
        height: 220px;
        margin-bottom: 24px;
        overflow: hidden;

        @include media(md) {
            margin: 36px auto 0 auto;
            width: 952px;
            height: 440px;
        }

        #{$root}--HasBackground & {
            @include media(md) {
                margin-bottom: 36px;
            }
        }
    }
}
